p {
    margin: 0;
    padding: 0;
}
h2 {
    margin: 0;
    padding: 0;
}

.gross-margin-main-container {
    background-color: #1e2631;
    min-width: 320px;
    /* width: 395px; */
    height: 190px;
    border-radius: 4px;
    padding: 16px;
}
.gross-margin-header {
    font-size: 12px;
    font-weight: 400;
    color: #d1d1d1;
    margin-bottom: 16px;
}
.gross-margin-content-container h2 {
    font-size: 24px;
    font-weight: 400;
    color: #00aeef;
    padding-bottom: 12px;
}

.gross-margin-content-container p {
    color: #adb5bd;
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
    margin-bottom: 25px;
}
.gross-margin-graph-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.gross-margin-hr-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
.gross-margin-hr-container div {
    border: 0.5px solid #3c485a;
    width: 97%;
}
