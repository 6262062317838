p {
    margin: 0;
    padding: 0;
}

.sales-by-brand-and-store-container {
    min-width: 400px;
    padding: 16px;
    height: 370px;
    margin-top: 24px;
    /* margin-bottom: 24px; */
    background-color: #1e2631;
    border-radius: 4px;
}
.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
}
.header p:nth-child(1) {
    color: #d1d1d1;
    font-size: 12px;
    font-weight: 400;
}
.header p:nth-child(2) {
    color: #4f5b6d;
    font-size: 10px;
    font-weight: 500;
}

.sales-by-brand-and-store-container .content {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-bottom: 16px;
}
.sales-by-brand-and-store-container .content p:nth-child(1) {
    font-size: 24px;
    font-weight: 400;
    color: #00aeef;
}
.sales-by-brand-and-store-container .content p:nth-child(2) {
    font-size: 12px;
    font-weight: 400;
    color: #d1d1d1;
}
.hr-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}
.hr-container div {
    border: 0.5px solid #3c485a;
    width: 97%;
}
.main-data {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.main-data-single-item {
    display: flex;
    justify-content: space-between;
    height: 38px;
    align-items: center;
    padding: 9px;
    border-radius: 4px;
}
.main-data-single-item:nth-child(odd) {
    background-color: #1a222d;
}
.image-portion-container {
    display: flex;
    align-items: center;
    gap: 8px;
}
.image-holder {
    height: 30px;
    width: 60px;
    border-radius: 4px;
    background-color: #10141b;
    position: relative;
    display: flex;
    justify-content: center;
}
.image-portion-container p {
    font-size: 12px;
    font-weight: 400;
    color: #d1d1d1;
}
.main-data-single-item p {
    font-size: 12px;
    color: #d1d1d1;
}

.image-holder img {
    /* position: absolute; */
    width: 40px;
    top: 8px;
    left: 10px;
    padding: 5px;
}
